import { SocialSharingTags } from '@/components/molecules/SocialSharingTags';
import '@/styles/globals.css';
import { BasePageProps, Page } from '@/types/page';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Lexend_Deca } from 'next/font/google';

interface CustomAppProps<T> extends Omit<AppProps, 'pageProps'> {
  Component: Page<BasePageProps>;
  pageProps: BasePageProps<T>;
}

const IFrameEntryPoint = dynamic(
  () =>
    import('@/components/entry-points/IFrameEntryPoint').then(
      (mod) => mod.IFrameEntryPoint
    ),
  { ssr: false }
);

const MarketPlaceEntryPoint = dynamic(
  () =>
    import('@/components/entry-points/MarketPlaceEntryPoint').then(
      (mod) => mod.MarketPlaceEntryPoint
    ),
  { ssr: false }
);

const lexendDeca = Lexend_Deca({ subsets: ['latin'] });

function App<T>({
  Component,
  pageProps: {
    noHeader = false,
    noFooter = false,
    printHideHeader = false,
    user,
    metadata,
    ...restPageProps
  },
}: CustomAppProps<T>) {
  const shellComponent = Component.isIframe ? (
    <IFrameEntryPoint>
      <Component {...restPageProps} />
    </IFrameEntryPoint>
  ) : (
    <MarketPlaceEntryPoint
      noHeader={noHeader}
      noFooter={noFooter}
      user={user}
      printHideHeader={printHideHeader}
    >
      <Component {...restPageProps} />
    </MarketPlaceEntryPoint>
  );

  return (
    <>
      <SocialSharingTags {...metadata} />
      <style jsx global>{`
        body {
          font-family: ${lexendDeca.style.fontFamily};
        }
      `}</style>
      {shellComponent}
    </>
  );
}

export default appWithTranslation(App);
